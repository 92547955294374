import {HlaExtendedModel, HlaLocusModel} from '@matchsource/models/hla';
import {HlaDto} from './declarations';
import {glstringSerializer} from '@matchsource/api/hla-shared';
import {formatLeaderValues, formatMethodLetter, formatType, getMatchGradeClass, mapLeaderTyping} from './utils';

const NON_PERCENTAGE_LOCUSES = ['DPB1', 'DQA1', 'DPA1'];

const mapMatchData = (hlaData: HlaExtendedModel, data: Partial<HlaDto>): void => {
  hlaData.matchGrade1 = data.matchGrade1 || '';
  hlaData.matchGrade2 = data.matchGrade2 || '';
  hlaData.sign1 = data.pdi1 || '';
  hlaData.sign2 = data.pdi2 || '';
  hlaData.matchLikelihood = !data.matchLikelihood || data.matchLikelihood > 99 ? 0 : data.matchLikelihood;

  hlaData.matchGradeSign1 = `${hlaData.matchGrade1}${hlaData.sign1}`;
  hlaData.matchGradeSign2 = `${hlaData.matchGrade2}${hlaData.sign2}`;
  hlaData.matchLikelihoodFormatted = data.matchLikelihood !== null ? `${hlaData.matchLikelihood}%` : '';

  hlaData.matchGradeClass1 = getMatchGradeClass(hlaData.matchGrade1);
  hlaData.matchGradeClass2 = getMatchGradeClass(hlaData.matchGrade2);
};

const addFormattedValues = (hlaData: HlaExtendedModel, data: Partial<HlaDto>): void => {
  hlaData.type1Formatted = formatType({
    value: hlaData.type1,
    typingMethod: hlaData.typingMethod1,
    hlaExprChar: data.hlaExprChar1,
  });
  hlaData.type2Formatted = formatType({
    value: hlaData.type2,
    typingMethod: hlaData.typingMethod2,
    hlaExprChar: data.hlaExprChar2,
  });

  hlaData.alleleRevealAntigen1Formatted = formatType({
    value: hlaData.alleleRevealAntigen1,
    typingMethod: hlaData.typingMethod1,
    hlaExprChar: data.hlaExprChar1,
  });
  hlaData.alleleRevealAntigen2Formatted = formatType({
    value: hlaData.alleleRevealAntigen2,
    typingMethod: hlaData.typingMethod2,
    hlaExprChar: data.hlaExprChar2,
  });

  hlaData.methodLetter = formatMethodLetter(hlaData.method);
};

/**
 * Returns fields used in new components/templates (Angular)
 */
const createHlaModel = (data: Partial<HlaDto> = {}): HlaExtendedModel => {
  const hlaData = {} as HlaExtendedModel;
  hlaData.name = data.locus || data.name || '';
  hlaData.type1 = data.antigen1 || data.type1 || '';
  hlaData.type2 = data.antigen2 || data.type2 || '';
  hlaData.alleleRevealAntigen1 = data.alleleRevealAntigen1 || '';
  hlaData.alleleRevealAntigen2 = data.alleleRevealAntigen2 || '';
  hlaData.noTypingAvailable = !!data.noTypingAvailable;
  hlaData.partOfGroup1 = !!data.partOfGroup1;
  hlaData.partOfGroup2 = !!data.partOfGroup2;
  hlaData.onlyPossibleAllele1 = !!data.onlyPossibleAllele1;
  hlaData.onlyPossibleAllele2 = !!data.onlyPossibleAllele2;
  hlaData.typingMethod1 = data.typingMethod1 || data.method || data.type1Method || data.objectType1 || '';
  hlaData.typingMethod2 = data.typingMethod2 || data.method || data.type2Method || data.objectType2 || '';
  hlaData.method = data.method || hlaData.typingMethod1 || hlaData.typingMethod2 || '';

  mapMatchData(hlaData, data);
  addFormattedValues(hlaData, data);

  hlaData.typingFormatted = `${hlaData.type1Formatted}\n${hlaData.type2Formatted || ' '}`;
  hlaData.typing = `${hlaData.type1}\n${hlaData.type2 || ' '}`;

  hlaData.glstring = data.glstring || '';
  hlaData.glstringBox = glstringSerializer.fromDTO(data.glstringBox);

  hlaData.glstringInd = data.glstringInd || false;
  hlaData.viewGlString = data.glstringInd || false;

  hlaData.leaderValue1 = data.leaderValue1;
  hlaData.leaderValue2 = data.leaderValue2;
  hlaData.leaderTyping = mapLeaderTyping(hlaData);

  hlaData.dpb1ExpressionValue1 = data.dpb1ExpressionValue1;
  hlaData.dpb1ExpressionValue2 = data.dpb1ExpressionValue2;

  return hlaData;
};

/**
 * Generates the same fields as HlaFactory (src/app-legacy/shared/search/services/hla.factory.js).
 * Despite createHlaModel returns fields used in old components/templates (AngularJs).
 * May generate more fields than necessary.
 */
const fromDTO = (data: Partial<HlaDto> = {}): HlaExtendedModel => {
  const hlaData = createHlaModel(data);
  hlaData.leaderValue1 = data.leaderValue1 || '';
  hlaData.leaderValue2 = data.leaderValue2 || '';
  hlaData.leaderTyping = mapLeaderTyping(hlaData);
  hlaData.type1AntibodyMatch = !!data.type1AntibodyMatch;
  hlaData.type2AntibodyMatch = !!data.type2AntibodyMatch;

  if (NON_PERCENTAGE_LOCUSES.indexOf(data.locus) !== -1) {
    hlaData.matchLikelihoodFormatted = '';
  }

  const {leaderValue1Formatted, leaderValue2Formatted} = formatLeaderValues(hlaData);
  hlaData.leaderValue1Formatted = leaderValue1Formatted;
  hlaData.leaderValue2Formatted = leaderValue2Formatted;

  hlaData.dpb1ExpressionValue1 = data.dpb1ExpressionValue1 || data.dpb1Expression || null;
  hlaData.dpb1ExpressionValue2 = data.dpb1ExpressionValue2 || data.dpb2Expression || null;

  return hlaData;
};

const mapHlaLocusDtoToModel = (input: Partial<HlaDto>): HlaLocusModel => {
  const type1Formatted = formatType({
    value: input.antigen1,
    typingMethod: input.typingMethod1,
    hlaExprChar: input.hlaExprChar1,
  });
  const type2Formatted = formatType({
    value: input.antigen2,
    typingMethod: input.typingMethod2,
    hlaExprChar: input.hlaExprChar2,
  });

  return {
    type1: input.antigen1,
    type2: input.antigen2,
    typingMethod1: input.typingMethod1,
    typingMethod2: input.typingMethod2,
    matchGrade1: input.matchGrade1,
    matchGrade2: input.matchGrade2,
    sign1: input.pdi1,
    sign2: input.pdi2,
    hlaExprChar1: input.hlaExprChar1,
    hlaExprChar2: input.hlaExprChar2,
    matchLikelihood: input.matchLikelihood,
    locus: input.locus,
    name: input.locus,
    partOfGroup1: input.partOfGroup1,
    partOfGroup2: input.partOfGroup2,
    noTypingAvailable: input.noTypingAvailable,
    onlyPossibleAllele1: input.onlyPossibleAllele1,
    onlyPossibleAllele2: input.onlyPossibleAllele2,
    alleleRevealAntigen1: input.alleleRevealAntigen1,
    alleleRevealAntigen2: input.alleleRevealAntigen2,
    type1AntibodyMatch: input.type1AntibodyMatch,
    type2AntibodyMatch: input.type2AntibodyMatch,
    leaderValue1: input.leaderValue1,
    leaderValue2: input.leaderValue2,
    dpb1ExpressionValue1: input.dpb1ExpressionValue1 || input.dpb1Expression || null,
    dpb1ExpressionValue2: input.dpb1ExpressionValue2 || input.dpb2Expression || null,
    type1Formatted,
    type2Formatted,
  };
};

export const hlaSerializer = {
  fromDTO,
  createHlaModel,
  mapHlaLocusDtoToModel,
};
